// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
#root {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    font-family: "Open Sans", sans-serif;
}

.error-multiple-tabs-view {
    height: 100vh;
    width: 100vw;
    display: flex;
}
.error-multiple-tabs-view p {
    margin: auto;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;IAEI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,aAAa;IACb,aAAa;IACb,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;AACjB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":["body,\n#root {\n    margin: 0;\n    padding: 0;\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    font-family: \"Open Sans\", sans-serif;\n}\n\n.error-multiple-tabs-view {\n    height: 100vh;\n    width: 100vw;\n    display: flex;\n}\n.error-multiple-tabs-view p {\n    margin: auto;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
